import { ResponsiveImage, SubTitle, Title } from 'components/Common';

import { Row, HalfCol, TextContainer, TitleRow, DescriptionRow, DescriptionCol, ThirdCol } from 'components/Grid';



const Data = {
  id: "twitch",
  image: require('./intro.png'),
  title: "Commissions for Twitch.tv streamers",
  desc: "Twitch is designed to be a platform for content, including esports tournaments, personal streams of individual players, and gaming-related talk shows. Working in this dynamic environment is a great achievement in my career as a designer and a person who is interested in video games.",
  tags: ["Twitch emotes", "Merch design", "Mascot concepts", "Plushies", "Screen banners"],
  data: <>
    <Row>
      <ResponsiveImage src={require('./01.png')} alt="Twitch logo" roundCorners="bl" />
    </Row>
    <Row style={{minHeight: "900px"}}>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Commissions for Twitch.tv streamers</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>2021 for me began with an unexpected turn. A World of Warcraft streamer Sodapoppin started doing art competitions for his merchandise.</p></DescriptionCol>
            <DescriptionCol><p>Little did I know that this would start something I would be this proud of.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Sodapoppin</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>I was a part of his community for a long time, so I created a design that I felt would fit - a mean lil' cat on a tshirt. In the competition review, the feeling of being judged live by 40k people was exhilarating. Sodapoppin and his chat liked the cat - it was picked as one of the winners.</p>
            <p>Never before I was so proud. Never before I jumped around in so much joy.</p>
            </DescriptionCol>
            <DescriptionCol><p>Afterwards he let me know that he wanted this dumb cat to be his mascot.</p>
            <p>Fast forward more than a year of happy freelancing, I created a lot of various merch designs, plushies, emotes, subscriber badges, offline screens and all that jazz that any content creator on Twitch would want.</p>
            </DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./02.png')} alt="Sodapoppin portrait"/></HalfCol>
    </Row>
    <Row>
      <ThirdCol>
        <TextContainer>
          <TitleRow>
            <SubTitle>Sodapoppin</SubTitle>
            <Title>Logo design</Title>
          </TitleRow>
          <DescriptionRow>
            <p>Sodapoppin’s old avatar was a popular Twitch emote DatSheffy. It was so iconic that he himself was afraid of how his community would react to the change. We decided that the best course of action would be to combine his long-time picture with lil’ purp - the cat.</p>
          </DescriptionRow>
        </TextContainer>
      </ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./03.png')} alt="Sodapoppin logo" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./04.png')} alt="Sodapoppin logo alternative" /></ThirdCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./05.png')} alt="Sodapoppin emotes" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./22.png')} alt="Sodapoppin offline screen" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <SubTitle>Sodapoppin</SubTitle>
            <Title>Merch designs</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>Now a recognized character on Twitch, lil’ purp began with this very first design. A phrase Sodapoppin was accustomed to saying combined with a grumpy yet playful cat reflected who he is.</p></DescriptionCol>
            <DescriptionCol><p>I designed over 30 unique merch designs that the community and Sodapoppin himself happily wore, hung on their walls and decorated their rooms.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./06.png')} alt="Sodapoppin merch designs" /></HalfCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./07.png')} alt="Sodapoppin merch cat" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./08.png')} alt="Sodapoppin merch lizard" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./09.png')} alt="Sodapoppin merch back to wow" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./10.png')} alt="Sodapoppin merch risk of rain" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./10-2.png')} alt="Sodapoppin Chrome cat" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <SubTitle>Sodapoppin</SubTitle>
            <Title>Plushie design</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>One of my favorite projects because of the novelty factor. Seeing your design become a plushie? Dream come true.</p></DescriptionCol>
            <DescriptionCol><p>Made concept art of over 15 different designs but we picked out two that were most fitting.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./11.png')} alt="Sodapoppin plushie design" /></HalfCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./12.png')} alt="Sodapoppin plushie concept" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Saiiren</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>A steamer with over 155k followers on Twitch contacted me after she saw my works for Sodapoppin. I was super happy cause I was also watching and enjoying her GTA V streams.</p></DescriptionCol>
            <DescriptionCol><p>She is a really kind and sociable person that commissioned me to design a mascot and emotes for her channel.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./13.png')} alt="Saiiren" /></HalfCol>
    </Row>
    <Row>
      <ThirdCol>
        <TextContainer>
          <TitleRow>
            <SubTitle>Saiiren</SubTitle>
            <Title>Mascot design</Title>
          </TitleRow>
          <DescriptionRow>
            <p>After several iterations, we decided what we are going for - a cute yellow fox that represented what she loved and who she felt like. Meet Lemon!</p>
          </DescriptionRow>
        </TextContainer>
      </ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./14.png')} alt="Saiiren mascot" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./15.png')} alt="Saiiren emoji" /></ThirdCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./16.png')} alt="Saiiren mascot variants" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./17.png')} alt="Saiiren mascot details" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Sasha Grey</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>A surprise, to be sure, but a welcome one. It was my absolute honor to work for Sasha Grey - an American actress, model, writer, musician that is currently focusing on her gaming and livestreaming platform. She has over 1 million followers on Twitch.</p></DescriptionCol>
            <DescriptionCol><p>I created a concept of her new mascot Wormie and designed emotes for her channel.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./18.png')} alt="Sasha Grey" /></HalfCol>
    </Row>
    <Row>
      <ThirdCol>
        <TextContainer>
          <TitleRow>
            <SubTitle>Sasha Grey</SubTitle>
            <Title>Mascot design</Title>
          </TitleRow>
          <DescriptionRow>
            <p>The process in short. We started off with several different characters that would reflect her passion for making food. After long discussions, we found a long time character that was always on her screen while she streamed and travelled. It was Wormie.</p>
          </DescriptionRow>
        </TextContainer>
      </ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./19.png')} alt="Sasha Grey Wormie" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./20.png')} alt="Sasha Grey Wormie colors" /></ThirdCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./21.png')} alt="Sasha Grey Wormie emojis" />
    </Row>
  </>,
};

export default Data;
