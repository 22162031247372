import { ResponsiveImage, SubTitle, Title } from 'components/Common';

import { Row, HalfCol, TextContainer, TitleRow, DescriptionRow, DescriptionCol, ThirdCol } from 'components/Grid';

const Data = {
  id: "illustrations",
  image: require('./intro.png'),
  title: "2D illustrations",
  desc: "Ever since I was little I was drawing some derpy dragons breathing fire and other weird fantasy creatures. Somehow, I felt maybe this will lead somewhere. And it kinda did.",
  tags: ["How Monika has fun", "Food", "Game of Thrones"],
  data: <>
    <Row>
      <ResponsiveImage src={require('./01.png')} alt="Doughnuts" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Illustrations</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>I could spend hours perfecting a design or create illustrations that need to be done yesterday. Either way, I enjoy this type of work the most.</p></DescriptionCol>
            <DescriptionCol><p>Trying new techniques to be more efficient and create compelling art.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./02.png')} alt="Coffee cup illustration" /></HalfCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./03.jpg')} alt="Lifelife Witch Doctor" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./04.png')} alt="Cyberpunk 2077 illustration" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./05.jpg')} alt="Dracarys fire Game of Thrones" />
    </Row>
    </>
};

export default Data;
