import { ResponsiveImage, SubTitle, Title } from 'components/Common';

import { Row, HalfCol, TextContainer, TitleRow, DescriptionRow, DescriptionCol, ThirdCol } from 'components/Grid';

const Data = {
  id: "branding",
  image: require('./branding.png'),
  title: "Branding",
  desc: "Branding is like pizza. At first glance, it might seem very subjective. Some toppings you like, some maybe not so much. But when you taste a remarkable pizza, you think to yourself - what makes it so good? Well, it’s basically hard work, lots of experimentation and research. I'm trying to get there.",
  tags: ["Logotypes", "3D illustrations", "Web design", "Merch", "Packaging", "Game art"],
  data: <>
  <Row>
    <ResponsiveImage src={require('./01.png')} alt="Lit Robotics main image"/>
  </Row>
  <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Lit Robotics branding</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>Lit Robotics is my ongoing project, born from a long lasting friendship of 5 professionals from different fields. An idea brought to the table, turned to an elaborate plan. A way forward in an ever-evolving life.</p>
            <p>We want to help individuals and companies reach highest efficiency possible through functional and reliable solutions, be it in professional setting, or everyday life.</p>
            </DescriptionCol>
            <DescriptionCol><p>Branding for the company was inspired by blueprints and intricate schematics engineers use for their plans. The logo mark is a hard-edged L and R that are moving forward. Color palette was chosen to be both in the world of blueprints and metallic substances.</p>
            </DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./02.png')} alt="Lit Robotics logo design"/></HalfCol>
    </Row>
    <Row>
    <ResponsiveImage src={require('./03.png')} alt="Lit Robotics color palette"/>
  </Row>
  <Row>
      <ThirdCol><ResponsiveImage src={require('./04.png')} alt="CargoScan PS image" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./05.png')} alt="Functional image" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./06.png')} alt="Customizable image" /></ThirdCol>
    </Row>
    <Row>
    <ResponsiveImage src={require('./07.png')} alt="Lit Robotics web design landing page"/>
  </Row>
  <Row>
    <ResponsiveImage src={require('./08.png')} alt="Lit Robotics web design"/>
  </Row>
  <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Fullreach merch</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>At Fullreach, they use data and creativity to help brands start meaningful conversations and gain new customers. The brand brief was given:
            <li>They use their own experience, both personal and professional, to drive themessage across;</li>
            <li>Quiet amid all the noise, providing a different perspective;</li>
            <li>Emphasis on ownership. Instead of feeling constrained by roles and rules, employees can follow their instincts. </li>
            </p>
            </DescriptionCol>
            <DescriptionCol><p>The design elements that I chose for designs were to show getting the message across chaotic waters, going the extra mile among the competitors and reaching the goal, however difficult it might be.</p>
            </DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./09.png')} alt="Fullreach merch design mockup"/></HalfCol>
    </Row>
    <Row>
    <ResponsiveImage src={require('./13.png')} alt="Fullreach merch water ripples"/>
  </Row>
    <Row>
      <ThirdCol><ResponsiveImage src={require('./10.png')} alt="Fullreach merch design lines mountain" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./11.png')} alt="Fullreach merch design loading circle" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./12.png')} alt="Fullreach merch design Mount Everest coordinates" /></ThirdCol>
    </Row>
    <Row>
    <ResponsiveImage src={require('./14.png')} alt="Fullreach team wearing merch"/>
  </Row>
  <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>GCG packaging</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>Global Chemical Gate has worked to bring the highest quality disinfection and cleaning products, as well as personal protective equipment to the clients across the world.</p>
            </DescriptionCol>
            <DescriptionCol><p>The goal of the design was to create a minimalistic and modular visual system that would stand out among the competitors.</p>
            </DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./15.png')} alt="GCG packaging Sterihand"/></HalfCol>
    </Row>
    <Row>
      <ThirdCol><ResponsiveImage src={require('./16.png')} alt="GCG packaging Sterihand Gel" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./17.png')} alt="GCG packaging Sterihand Wipes" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./18.png')} alt="GCG packaging Sterihand large 5L" /></ThirdCol>
    </Row>
    <Row>
    <ResponsiveImage src={require('./20.png')} alt="Fullreach team wearing merch"/>
  </Row>
  <Row>
    <ResponsiveImage src={require('./19.png')} alt="Fullreach team wearing merch"/>
  </Row>
  <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Game for NASA Space Apps challenge</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>Participants worldwide come together in teams during an intense 48-hour hackathon to offer solutions to challenges NASA poses about some of the world’s toughest problems on Earth and in space. I participated with a Netherlands university’s Space Society Twente team to create a game exploring COVID-19 problems. We developed a prototype game Oversight.</p>
            </DescriptionCol>
            <DescriptionCol><p>In short, you have to make sure you quarantine or get people to the hospital at the right time. You have a limited number of tests to perform to find out which people are sick.</p>
            <p>It was a super fun experience and one of many hackatons I had the opportunity to participate.</p>
            </DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./21.png')} alt="Oversight NASA logo design"/></HalfCol>
    </Row>
    <Row>
    <ResponsiveImage src={require('./22.png')} alt="Oversight NASA game image"/>
  </Row>
  <Row>
    <ResponsiveImage src={require('./23.png')} alt="Oversight NASA game color palette"/>
  </Row>
</>,
};

export default Data;
