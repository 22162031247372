import styled from "styled-components";
import { QuarterCol, Row } from "./Grid";
import { ReactComponent as EmailIcon } from 'images/icon_email.svg';
import { ReactComponent as TwitterIcon } from 'images/icon_twitter.svg';
import { ReactComponent as LinkedInIcon } from 'images/icon_linkedin.svg';

const FooterContainer = styled.div`
  border-top-right-radius: 36px;
  border-top-left-radius: 36px;
  background: #B6F6FF;
`;

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const AlignedRow = styled(Row)`
  align-items: center;
  margin: 0;
`;

export default function Footer() {
  return <FooterContainer>
    <AlignedRow>
      <QuarterCol>2022 © Monika Vosyliute</QuarterCol>
      <QuarterCol><a href="mailto:monika.vosyliute@gmail.com" target="_blank" rel="noreferrer"><ContactContainer><EmailIcon /> monika.vosyliute@gmail.com</ContactContainer></a></QuarterCol>
      <QuarterCol><a href="https://twitter.com/monikavosyliute" target="_blank" rel="noreferrer"><ContactContainer><TwitterIcon /> @MonikaVosyliute</ContactContainer></a></QuarterCol>
      <QuarterCol><a href="https://www.linkedin.com/in/monika-vosyliute" target="_blank" rel="noreferrer"><ContactContainer><LinkedInIcon /> monika-vosyliute</ContactContainer></a></QuarterCol>
    </AlignedRow>
  </FooterContainer>
}