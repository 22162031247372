import styled from 'styled-components';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import NavBar from './components/NavBar';
import WorkListPage from './pages/WorkListPage';
import AboutPage from './pages/About';
import ContactsPage from './pages/Contacts';
import WorkPage from './pages/Work';
import NotFoundPage from './pages/NotFound';
import { useEffect } from 'react';
import { Container } from 'components/Grid';
import Footer from 'components/Footer';

const AppContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);
  
  return children;
}

function App() {
  return (
    <AppContainer>
      <Router>
        <ScrollToTop/>
        <NavBar/>
        <Container>
          <Routes>
            <Route exact path="/" element={<WorkListPage/>} />
            <Route path="/work/:workId" element={<WorkPage/>} />
            <Route path="/about" element={<AboutPage/>} />
            <Route path="/contacts" element={<ContactsPage/>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer/>
        </Container>
      </Router>
    </AppContainer>
  );
}

export default App;
