import { useParams } from "react-router-dom"
import WorkData from "content";
import NotFoundPage from "pages/NotFound";

export default function WorkPage(props) {
    let params = useParams();
    let workId = params.workId;

    console.log(WorkData);
    let data = WorkData.find((work) => work.id === workId);

    if (!data) {
        return <NotFoundPage/>
    }

    return data.data;
}