import styled from "styled-components";
import media from "styled-media-query";

export const Title = styled.span`
  font-weight: 700;
  font-size: 54px;
  line-height: 1.2em;

  ${media.lessThan('large')`
    font-size: 36px;
  `}
`;

export const SubTitle = styled.span`
  color: #969696;
`;

export const ResponsiveImage = styled.img`
  max-width:100%;
  max-height:100%;

  ${({roundCorners}) => (roundCorners || "").includes('tr') && `
    border-top-right-radius: 36px;
  `}

  ${({roundCorners}) => (roundCorners || "").includes('br') && `
    border-bottom-right-radius: 36px;
  `}

  ${({roundCorners}) => (roundCorners || "").includes('bl') && `
    border-bottom-left-radius: 36px;
  `}

  ${({roundCorners}) => (roundCorners || "").includes('tl') && `
    border-top-left-radius: 36px;
  `}
`;

export const Button = styled.button`
  align-self: flex-start;
  padding: 8px 12px;
  background: #9069FF;
  font-weight: 600;
  font-size: 14px;
  line-height: 157%;
  color: #FFFFFF;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #CC5FFF;
    color: #FFFFFF;
  }
`;
