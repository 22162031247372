import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 72px 72px 72px 72px;
  margin-bottom: 64px;
  gap: 72px;

  ${media.lessThan('large')`
    padding: 36px 36px 36px 36px;
    margin-bottom: 36px;
    gap: 36px;
    flex-wrap: wrap;
  `}
`;

export const Col = styled.div`
  width: 100%;
`;

export const HalfCol = styled.div`
  width: 50%;

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

export const ThirdCol = styled.div`
  width: 33%;

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

export const QuarterCol = styled.div`
  width: 25%;

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 32px;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  height: 50%;
`;

export const DescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;

  ${media.lessThan('large')`
    flex-wrap: wrap;
  `}
`;

export const DescriptionCol = styled.div`
  width: 50%;
  font-size: 16px;

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

