import { ResponsiveImage, SubTitle, Title } from 'components/Common';

import { Row, HalfCol, TextContainer, TitleRow, DescriptionRow, DescriptionCol, ThirdCol } from 'components/Grid';

const Data = {
  id: "coingate",
  image: require('./intro.png'),
  title: "Design work for CoinGate",
  desc: "The world of cryptocurrencies never sleeps. The same can be said about crypto companies. Working at one is one of the best ways to get hands on experience in the agile startup environment and learn about the depths of digital marketing.",
  tags: ["Social media visuals", "Blog visuals", "Comics", "3D illustrations", "Ads and banners", "Presentations"],
  data: <>
  <Row>
    <ResponsiveImage src={require('./01.png')} alt="CoinGate logo"/>
  </Row>
  <Row style={{minHeight: "900px"}}>
    <HalfCol>
      <TextContainer>
        <TitleRow>
          <Title>Design work for CoinGate</Title>
        </TitleRow>
        <DescriptionRow>
          <DescriptionCol><p>CoinGate has a team of creative, curious, witty, and playful people that make the crypto gears turn. They believe DeFi is the future, and present, of finance. Therefore, the team is doing everything they can to make this dream accessible to everyone.
          <ul>
            <li>social media &amp; blog visuals</li>
            <li>ads &amp; banners</li>
            <li>business presentations</li>
            <li>conference prints</li>
            <li>infographics</li>
          </ul>
          </p>
          </DescriptionCol>
          <DescriptionCol><p>Dedicated tasks to freelancers and interns. Reviewed tasks in the UI/UX department, co-created landing pages with illustrations, promos, email automations and edited UI design systems.</p></DescriptionCol>
        </DescriptionRow>
      </TextContainer>
    </HalfCol>
  </Row>
  <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Social media &amp; blog visuals</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>This is definitely my cup of tea and bread and butter.</p>
            <p>Working closely with our content and social media people, we released a lot of high-ranking blog posts.
            <ul>
            <li>Added additional infographics and visuals to blog’s content to increase read time;</li>
            <li>Optimized the sizes of images for faster loading.</li>
          </ul>
              </p></DescriptionCol>
            <DescriptionCol><p>Even though CoinGate’s following on social media is not reaching the moon (not yet) but we did several viral posts. Focusing on certain cryptocurrency communities, we released posts that were tailored to attract attention.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./02.png')} alt="Web hosting" /></HalfCol>
    </Row>
        <Row>
      <ThirdCol><ResponsiveImage src={require('./03.png')} alt="VPS blog image for Instagram" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./04.png')} alt="Bitcoin pizza day for Instagram" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./05.png')} alt="Buy crypto with credit card" /></ThirdCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./06.png')} alt="VPN and trading" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Having fun in socials</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>Having the freedom to experiment with visuals, I created several comics, enhancing them with either storytelling or meme material.</p>
            <p>Later on, we tried creating branded meme templates  and they went well for our socials.</p></DescriptionCol>
            <DescriptionCol><p>There’s also nothing more exciting than to create illustrations of food. Who doesn’t love good food?</p>
            <p>Also, vaporwave aesthetics was something our target audience seemed to enjoy, so spent a lot of time exploring that area.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./07.png')} alt="This is fine comic" /></HalfCol>
    </Row>
    <Row>
      <ThirdCol><ResponsiveImage src={require('./08.png')} alt="Bull versus bear market comic" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./09.png')} alt="Shiba bringing coins comic" /></ThirdCol>
      <ThirdCol><ResponsiveImage src={require('./10.png')} alt="Christmas comic" /></ThirdCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./11.png')} alt="Dogecoin 8 year birthday" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./12.png')} alt="Breakfast blog post visual" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Ads &amp; banners</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>So many ads, so many banners. So much to do, so little time.</p>
            <p>A huge number of ads were created for our gift card product on Facebook. After several ad campaigns, we analyzed which visuals and text were performing the best and applied what we learned.</p></DescriptionCol>
            <DescriptionCol><p>The same ad campaign experiments were conducted to figure out what works for our B2B product on LinkedIn.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./13.png')} alt="1 click crypto payments" /></HalfCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./14.png')} alt="Ad testing A" />
    </Row>
    <Row>
      <ResponsiveImage src={require('./15.png')} alt="Ad testing B" />
    </Row>
    <Row>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Presentations</Title>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol><p>Created a lot of presentations together with people from other departments. They were suited for potential investors and business to use CoinGate’s services.</p></DescriptionCol>
            <DescriptionCol><p>Enriched them with infographics, explanatory visuals and clean, easy to read design.</p></DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
      <HalfCol><ResponsiveImage src={require('./16.png')} alt="Crypto users growth infographic" /></HalfCol>
    </Row>
    <Row>
      <ResponsiveImage src={require('./17.png')} alt="Benefits of cryptocurrencies presentation" />
    </Row>
</>,
};

export default Data;
