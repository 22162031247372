
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Button, ResponsiveImage, Title } from './Common';
import { Row, HalfCol, TextContainer, TitleRow, DescriptionRow, DescriptionCol } from './Grid';

const TagsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const Tag = styled.div`
  color: #969696;
`

const OddRow = styled(Row)`
  flex-direction: row-reverse;

  ${media.lessThan('large')`
    flex-direction: row;
  `}
`;

function Description(props) {
  let tags = props.tags.map((tag, i) => <Tag key={i}>{tag}</Tag>);

  return (
    <TextContainer>
      <TitleRow>
        <Title>{props.title}</Title>
        <Link to={`/work/${props.id}`}><Button>View project &gt;</Button></Link>
      </TitleRow>
      <DescriptionRow>
        <DescriptionCol><p>{props.desc}</p></DescriptionCol>
        <DescriptionCol>
          <p>What you can find here:</p>
          <TagsContainer>{tags}</TagsContainer>
        </DescriptionCol>
      </DescriptionRow>
    </TextContainer>
  )
}

export default function WorkIntro(props) {
  let RowComp = props.odd ? OddRow : Row;

  // Round image bottom left or bottom right corner
  let roundCorners = props.odd ? "bl" : "br";

  return (<RowComp>
    <HalfCol><ResponsiveImage src={props.image} roundCorners={roundCorners} alt="test" /></HalfCol>
    <HalfCol><Description {...props}/></HalfCol>
  </RowComp>);
}