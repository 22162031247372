import styled from "styled-components";
import { ResponsiveImage, SubTitle, Title } from "components/Common";
import { HalfCol, DescriptionCol, DescriptionRow, Row, TextContainer, TitleRow } from "components/Grid";
import contacts_img from 'images/letter-yellow.png';
import { ReactComponent as EmailIcon } from 'images/icon_email.svg';
import { ReactComponent as TwitterIcon } from 'images/icon_twitter.svg';
import { ReactComponent as LinkedInIcon } from 'images/icon_linkedin.svg';
import { ReactComponent as EmojiPointLeft } from 'images/emoji_point_left.svg';

const ContactContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export default function ContactsPage() {
  return (<Row>
    <HalfCol>
      <TextContainer>
        <TitleRow>
          <Title>Get in touch <EmojiPointLeft/></Title>
          <SubTitle><i>Not literally</i></SubTitle>
        </TitleRow>
        <DescriptionRow>
          <DescriptionCol>
            <a href="mailto:monika.vosyliute@gmail.com" target="_blank" rel="noreferrer"><ContactContainer><EmailIcon /> monika.vosyliute@gmail.com</ContactContainer></a>
            <a href="https://twitter.com/monikavosyliute" target="_blank" rel="noreferrer"><ContactContainer><TwitterIcon /> @MonikaVosyliute</ContactContainer></a>
            <a href="https://www.linkedin.com/in/monika-vosyliute" target="_blank" rel="noreferrer"><ContactContainer><LinkedInIcon /> monika-vosyliute</ContactContainer></a>
          </DescriptionCol>
          <DescriptionCol><p>Drop me a message if you have some designing to do. Aaaand let’s get to it.</p></DescriptionCol>
        </DescriptionRow>
      </TextContainer>
    </HalfCol>
    <HalfCol><ResponsiveImage src={contacts_img} alt="About image" roundCorners="bl"/></HalfCol>
  </Row>)
}
