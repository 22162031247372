import { Link } from "react-router-dom"
import { SubTitle, Title } from "components/Common"
import { HalfCol, DescriptionCol, DescriptionRow, Row, TextContainer, TitleRow } from "components/Grid"

export default function NotFoundPage() {
  return <Row>
    <HalfCol></HalfCol>
    <HalfCol>
      <TextContainer>
        <TitleRow>
          <Title>404</Title>
          <SubTitle>Not Found</SubTitle>
        </TitleRow>
        <DescriptionRow>
          <DescriptionCol><Link to="/">Back to home</Link></DescriptionCol>
        </DescriptionRow>
      </TextContainer>
    </HalfCol>
  </Row>
}