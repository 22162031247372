import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useEffect, useRef } from 'react';

const Container = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 36px;

  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
`;

const LogoContainer = styled.div`
  & a, a:visited, a:hover, a.active {
    font-weight: 600;
    text-decoration: none;
    color: #121212;
  }
`;

const MenuToggle = styled.input`
  display: none;
`;

const MenuButtonContainer = styled.label`
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  ${media.lessThan('large')`
    display: flex;
  `}
`;

const MenuButton = styled.div`
  &, &::before, &::after {
    display: block;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }

  &::before {
    content: '';
    margin-top: -8px;
  }

  &::after {
    content: '';
    margin-top: 8px;
  }

  #menu-toggle:checked + ${MenuButtonContainer} &::before {
    transform: translateY(8px) rotate(0deg);
  }

  #menu-toggle:checked + ${MenuButtonContainer} & {
    transform: rotate(45deg);
  }

  #menu-toggle:checked + ${MenuButtonContainer} &::after {
    transform: translateY(-8px) rotate(-90deg);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color 600ms cubic-bezier(0.23, 1, 0.32, 1);
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 5;
  pointer-events: none;

  #menu-toggle:checked ~ & {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${media.lessThan('large')`
    position: fixed;
    padding-top: 80px;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    background: #fff;
    flex-direction: column;
    z-index: 9;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateX(100%);

    #menu-toggle:checked ~ & {
      box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.1);
      transform: translateX(0);
    }
  `}
`;

const Item = styled.li`
  margin: 0 24px;
  overflow: hidden;

  & a {
    font-weight: 700;
  }

  ${media.lessThan('large')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px;
    width: 100%;
    color: #000;
    background-color: #fff;
    
    & a {
      font-size: 20px;
    }
  `}
`;

export default function NavBar() {
  const toggle = useRef(null);

  const { pathname } = useLocation();
  
  useEffect(() => {
    toggle.current.checked = false;
  }, [pathname]);

  return (
    <Container>
      <LogoContainer>
        <NavLink to='/'>Monika Vosyliute</NavLink>
      </LogoContainer>
      <MenuToggle id="menu-toggle" type="checkbox" ref={toggle} />
      <MenuButtonContainer htmlFor="menu-toggle">
        <MenuButton />
      </MenuButtonContainer>
      <Menu>
        <Item><NavLink to='/'>Works</NavLink></Item>
        <Item><NavLink to='/about'>About me</NavLink></Item>
        <Item><NavLink to='/contacts'>Contacts</NavLink></Item>
      </Menu>
      <Overlay />
    </Container>
  )
}