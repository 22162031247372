import Splash from 'components/Splash';
import WorkIntro from 'components/WorkIntro';
import WorkData from 'content';

export default function WorkListPage() {
  let works = WorkData.map((work, index) => <WorkIntro {...work} key={work.id} odd={index % 2 !== 0}/>)
  return <>
    <Splash/>
    {works}
  </>
}