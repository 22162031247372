import styled, { keyframes } from "styled-components"
import { HalfCol, Row } from "./Grid"
import splash from 'images/splash.jpg';
import { Button, Title } from "./Common";
import media from "styled-media-query";
import { NavLink } from "react-router-dom";

const animation = keyframes`
 0% { transform: rotate(0deg) translate(-20%, -20%); }
 25% { transform: rotate(90deg) translate(-20%, 20%); }
 50% { transform: rotate(180deg) translate(20%, 20%); }
 75% { transform: rotate(270deg) translate(20%, -20%); }
 100% { transform: rotate(360deg) translate(-20%, -20%); }
`;

const SplashAnimation = styled.div`
  position: absolute;
  width: 3240px;
  height: 3240px;
  z-index: -2;
  background-image: url(${splash});
  animation-name: ${animation};
  animation-duration: 300s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: -80s;

  ${media.lessThan('large')`
    animation-delay: 0s;
  `}
`;

const SplashGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(90deg, #FA00FFFF, #FA00FF00, #FA00FF00);

  ${media.lessThan('large')`
    background: linear-gradient(90deg, #FA00FFFF, #FA00FF00);
  `}
`;

const SplashContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100vh - 80px);
  border-bottom-right-radius: 36px;
  border-bottom-left-radius: 36px;

  margin-bottom: 136px;
  ${media.lessThan('large')`
    margin-bottom: 72px;
  `}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  height: 100%;
  padding-left: 80px;
  padding-bottom: 80px;

  ${media.lessThan('large')`
    padding: 0;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlackButton = styled(Button)`
  background: #121212;
  color: #FFFFFF;

  &:hover {
    background: #FFFFFF;
    color: #121212;
  }
`;

export default function Splash() {
  return (<SplashContainer>
      <SplashAnimation/>
      <SplashGradient/>
      <Row style={{height: "100%"}}>
      <HalfCol>
        <TextContainer>
          <TitleContainer>
            <Title>Marketing designer,</Title>
            <Title>illustration enthusiast,</Title>
            <Title>curious human.</Title>
          </TitleContainer>
          <span>Social media / brand identity / digital &amp; print ads / merch / web design / 3d modeling</span>
          <NavLink to='/contacts'><BlackButton>Let's chat &gt;</BlackButton></NavLink>
        </TextContainer>
      </HalfCol>
    </Row>
  </SplashContainer>)
}
