import { ResponsiveImage, SubTitle, Title } from "components/Common";
import { HalfCol, DescriptionCol, DescriptionRow, Row, TextContainer, TitleRow, Col, QuarterCol } from "components/Grid";
import about_img from 'images/profile.jpg';
import { ReactComponent as EmojiWave } from 'images/emoji_wave.svg';
import styled from "styled-components";

const DESC1 = <>
  <p>Let me introduce myself. I’m a person who finds joy in creating designs that inspire people, make them laugh or wonder.</p>
  <p>If I had like 200 years more to live I would probably try a million different areas of design. Everything seems so interesting to dive into, explore every nook and cranny and find the answers to all questions related to art.</p>
</>;

const DESC2 = <p>I can help you or your business with design concepts and implementation - just send me a message.</p>;

const ColorContainer = styled.div`
  border-top-right-radius: 36px;
  border-top-left-radius: 36px;
  margin-bottom: -36px;
`;

const CompactRow = styled(Row)`
  margin: 32px 0;
  padding: 0;
`;

const SkillsCol = styled(QuarterCol)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SkillsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li {
    margin-top: 8px;
  }
`;

export default function AboutPage() {
  return <>
    <Row>
      <HalfCol><ResponsiveImage src={about_img} alt="About image" roundCorners="br" /></HalfCol>
      <HalfCol>
        <TextContainer>
          <TitleRow>
            <Title>Hello there! <EmojiWave/></Title>
            <SubTitle><i>General Kenobi</i></SubTitle>
          </TitleRow>
          <DescriptionRow>
            <DescriptionCol>{DESC1}</DescriptionCol>
            <DescriptionCol>{DESC2}</DescriptionCol>
          </DescriptionRow>
        </TextContainer>
      </HalfCol>
    </Row>
    <ColorContainer style={{backgroundColor: "#FFED4E"}}>
      <Row>
        <Col>
          <Title>Professional skills</Title>
          <CompactRow>
            <SkillsCol>
              <SkillsList>
                <li><b>Marketing design</b></li>
                <li>Illustration</li>
                <li>Social media &amp; blog visuals</li>
                <li>Digital ads</li>
                <li>Printing</li>
                <li>Merchandise</li>
                <li>Motion graphics &amp; video editing</li>
                <li>Presentations</li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>Branding</b></li>
                <li>Logo design</li>
                <li>Iconography</li>
                <li>Typography</li>
                <li>Color palettes</li>
                <li>Packaging</li>
                <li>Photography</li>
                <li>Art direction</li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>3D</b></li>
                <li>Modelling</li>
                <li>Animating</li>
                <li>Basic rigging</li>
                <li>Rendering</li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>Web design</b></li>
                <li>Responsive design</li>
                <li>Adaptive design</li>
                <li>UI component systems</li>
                <li>Basics of UX</li>
                <li>Basics of HTML/CSS/Javascript</li>
              </SkillsList>
            </SkillsCol>
          </CompactRow>
        </Col>
      </Row>
    </ColorContainer>
    <ColorContainer style={{backgroundColor: "#FC59FF"}}>
      <Row>
        <Col>
          <Title>Technical skills</Title>
          <CompactRow>
            <SkillsCol>
              <SkillsList>
                <li><b>Adobe Creative Suite</b></li>
                <li>Illustrator</li>
                <li>Photoshop</li>
                <li>Indesign</li>
                <li>After Effects</li>
                <li>Premiere Pro</li>
                <li>Animate, basics</li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>Figma</b></li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>3D</b></li>
                <li>Blender</li>
                <li>Cinema4D</li>
                <li>ZBrush</li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>Task management</b></li>
                <li>Jira</li>
                <li>Clickup</li>
                <li>Trello</li>
              </SkillsList>
            </SkillsCol>
          </CompactRow>
        </Col>
      </Row>
    </ColorContainer>
    <ColorContainer style={{backgroundColor: "#77DEFF"}}>
      <Row>
        <Col>
          <Title>Experience &amp; education</Title>
          <CompactRow>
            <SkillsCol>
              <SkillsList>
                <li><b>Vilnius Academy of Arts</b></li>
                <li>2014-2018</li>
              </SkillsList>
              <SkillsList>
              <li>Bachelor of Arts</li>
              <li>Graphic design</li>
              </SkillsList>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
              <li><b>Graphic designer at CoinGate</b></li>
              <li>2021 - now</li>
              </SkillsList>
              <p>Responsible for the brand of a cryptocurrency payment gateway CoinGate.
Created various visuals for social media, blog, ads &amp; banners, business
presentations, prints, emails. Dedicating tasks to freelancers. Reviewing
tasks in the UI/UX department, co-creating landing pages and editing UI
design systems.</p>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>Freelance graphic designer at Sodapoppin Enterprises, Inc.</b></li>
                <li>Nov 2020 - Jan 2022 (1 yr 3 mos)</li>
              </SkillsList>
              <p>Designed a logo for Twitch streamer Sodapoppin, subscriber badges,  emotes, offline screen, a large variety of merchandise designs, alerts. Working
with him is one of my greatest achievements and a dream come true.</p>
              <SkillsList>
                <li><b>Freelance graphic designer at Sasha Grey, Inc.</b></li>
                <li>Jan 2022 - Apr 2022 (4 mos)</li>
              </SkillsList>
              <p>Created a concept of Sasha Grey's new mascot Wormie on her Twitch channel
and designed emotes for her channel. A long process of developing the best
solution to elevate her brand.</p>
            </SkillsCol>
            <SkillsCol>
              <SkillsList>
                <li><b>Freelance graphic designer</b></li>
                <li>2017 - now</li>
              </SkillsList>
              <p>Building different projects throughout the years from concepts to realization. Developed numerous
brand deliverables (logos, web designs, brochures, video presentations,
product packaging, photoshoots).</p>
<p>Worked with customer aquisition company Fullreach, interior design shop ManicoDeco, medical supply company Global Chemical Gate, studio Uraga, Vilnius University.</p>
            </SkillsCol>
          </CompactRow>
        </Col>
      </Row>
    </ColorContainer>
  </>
}